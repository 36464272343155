import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import trust from "../../assets/image/png/trust.svg";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import RoomBanner from './Abanner';

import imgMobile from "../../assets/image/png/mobile.png";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import Videos from "../../sections/non/video.js";

const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`
  transform: rotate(10deg);
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 70%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    margin-left: 50px;
  }
`;

const Acfour = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section className="pb-md-5 mb pt-md-5" py="4" borderTop="2px solid #C2D9E1" borderBottom="2px solid #C2D9E1" >
      <Container className="pb-md-5 mb pt-md-5">

      <Row className="align-items-center">
        
        <Col lg="12" md="9">
          <Box className="pt-0 pt-lg-0">
          <HeroT>NON SURGICAL RHINOPLASTY <Span color="primary"> VS RHINOPLASTY</Span></HeroT>
          
            <Text>
            Unlike the non surgical rhinoplasty, the Surgical rhinoplasty is very costly and is usually a one-time purchase. When it comes to a nose job, there are limitations based on the patient’s overall anatomy, so many patients may not achieve their desired look due to the suitability. With greater risk and uncertainty, comes a heavy price. <br /><br />
Non-surgical nose job treatments are more affordable and a better option for many that want to experience the change and if it is the shape of the nose, rather than its size, that is causing concern, then the non-surgical rhinoplasty at Dermamina is an excellent, inexpensive option to change the shape of the nose, without invasive, costly surgery. Also Non-surgical rhinoplasty safer too. 

            </Text>
        
            <br />
              <Text>
              So what makes Dermamina so popular with nose filler in London? Well, for one we have completed thousands of non surgical nose jobs with results being amazing: 

              </Text><br />
              <Text><i><b>"Amazing! Everyone was so kind and friendly, Mina was amazing and so were the results" - Alice</b></i>
              <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank"> <img src={trust} width="150px" /></a>
                </Text><br />
             
            
              <Text>With such great results we have been told over and over by our clients that we are the best non surgical rhinoplasty clinic in London, if not the UK: </Text><br />
              <Text><i><b>"Five star service all the way , Mina is an absolute delight, her work speaks for itself, flawless perfection at its best" - Nina</b></i>
              <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank"> <img src={trust} width="150px" /></a>

              </Text>

          </Box>
        </Col>
       
      </Row>
       

      
      </Container>
    </Section>
  </>
);

export default Acfour;
