import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";

import {  Title, Section, Box, Text, Span } from "../../components/Core";


import { breakpoints } from "../../utils";

import imgC1 from "../../assets/image/png/nose1.png";
import imgC2 from "../../assets/image/png/nose2.png";
import imgC3 from "../../assets/image/png/nose3.png";
import imgC4 from "../../assets/image/png/nose4.png";
import imgC5 from "../../assets/image/png/nose5.png";
import imgC6 from "../../assets/image/jpeg/nose6.jpg";
import imgC7 from "../../assets/image/jpeg/nosefiller3.jpg";
import imgC8 from "../../assets/image/jpeg/nosefiller4.jpg";
import imgC9 from "../../assets/image/png/nose9.png";
import imgC10 from "../../assets/image/png/nose10.png";
import imgC11 from "../../assets/image/png/nose11.png";
import imgC12 from "../../assets/image/png/nose12.png";
import imgC13 from "../../assets/image/png/nose13.png";
import imgC14 from "../../assets/image/png/nose14.png";
import imgC15 from "../../assets/image/jpeg/nose15.jpg";
import imgC16 from "../../assets/image/jpeg/nose16.jpg";
import imgC17 from "../../assets/image/jpeg/nose17.jpg";
import imgC18 from "../../assets/image/jpeg/nose18.jpg";
import imgC19 from "../../assets/image/jpeg/nose30.jpg";


import imgC20 from "../../assets/image/jpeg/liquid2.jpg";
import imgC21 from "../../assets/image/jpeg/liquid5.jpg";

import imgC22 from "../../assets/image/jpeg/liquid1.jpg";
import imgC23 from "../../assets/image/jpeg/liquid6.png";

import imgC24 from "../../assets/image/jpeg/liquid3.jpg";
import imgC25 from "../../assets/image/jpeg/liquid4.jpg";

import imgC26 from "../../assets/image/jpeg/liquid7.jpg";
import imgC27 from "../../assets/image/jpeg/liquid8.jpg";



import imgC28 from "../../assets/image/jpeg/liquid-rhinoplasty.jpg";
import imgC29 from "../../assets/image/jpeg/liquid-rhinoplasty2.jpg";
import imgC30 from "../../assets/image/jpeg/liquid-rhinoplasty3.jpg";
import imgC31 from "../../assets/image/jpeg/liquid-rhinoplasty4.jpg";
import imgC32 from "../../assets/image/jpeg/liquid-rhinoplasty5.jpg";
import imgC33 from "../../assets/image/jpeg/liquid-rhinoplasty6.jpg";
import imgC34 from "../../assets/image/jpeg/liquid-rhinoplasty7.jpg";
import imgC35 from "../../assets/image/jpeg/liquid-rhinoplasty8.jpg";
import imgC36 from "../../assets/image/jpeg/liquid-rhinoplasty9.jpg";
import imgC38 from "../../assets/image/jpeg/liquid-rhinoplasty11.jpg";
import imgC39 from "../../assets/image/jpeg/liquid-rhinoplasty12.jpg";


import { device } from "../../utils";

import Himage from '../../assets/image/jpeg/home-bg2.jpg';

const Secti = styled.section`


position: relative;

background-color: #000;


@media screen and (max-width: 767px)
{

 }

`;

const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: url(${Himage}) no-repeat;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:0.1;


`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #000;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #000;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 3.2em;
     
      margin-bottom: 0.4em;
     
      color: #000;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 26px;
   
      }

`;

const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 26px;
  color: white;
  margin-bottom: 10px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
    margin-top: 10px;
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.shadow};
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.secondary};
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }
`;

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
  
    
    mx="3px"
   
    className={`${className}`}
    {...rest}
    css={`
      &:focus {
        outline: none;
      }
    `}
  >
    <Text color="dark" mb={4}>
      {children}
    </Text>
    <Box className={`d-flex justify-content-between`}>
      <Box className="d-flex justify-content-center align-items-start" mr={3}>
        <img src={image} alt="Liquid Nose Job London" className="img-fluid" />
      </Box>
      <div className="flex-grow-1">
        <Title variant="card" mb={0}>
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
  </Box>
);

const RhinogalLiqPAge = () => {
  const slickSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay:false,
   
    slidesToShow: 5,
    slidesToScroll: 5,
      speed: 500,
      cssEase: "linear",
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
  
   
      <Section>
        <Container className="pb-3 mb pt-3">
          <Row className="align-items-center">
            <Col md="9" lg="12" className="">
      
                   <Iwrap>


<Itext as="h2">Before And After Liquid Nose Job</Itext>
</Iwrap>
      <SecondText>
    See what is possible
      </SecondText>


            
             
            </Col>
            </ Row>

            <Row className="text-center">
            <Col lg="6" className="mb-3 mb-md-5">
<img src={imgC7} alt="Liquid Nose Job before and after" width="75%" />

</Col>
<Col lg="6" className="mb-3">
<img src={imgC8} alt="Liquid Nose Job before and after" width="75%" />

</Col>



            </Row>
            <Row>
         
            <Col lg="12"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="false"
            data-aos-delay="500"
            >
              <SliderStyled {...slickSettings}>

              <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC28}
                  >
                  
                  </ContentCard>
                </Box>


                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC29}
                  >
                  
                  </ContentCard>
                </Box>


                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC30}
                  >
                  
                  </ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC31}
                  >
                  
                  </ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC32}
                  >
                  
                  </ContentCard>
                </Box>


                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC33}
                  >
                  
                  </ContentCard>
                </Box>


                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC34}
                  >
                  
                  </ContentCard>
                </Box>


                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC35}
                  >
                  
                  </ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC36}
                  >
                  
                  </ContentCard>
                </Box>

          

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC38}
                  >
                  
                  </ContentCard>
                </Box>


                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC39}
                  >
                  
                  </ContentCard>
                </Box>


                
              <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC26}
                  >
                  
                  </ContentCard>
                </Box>

                
              <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC27}
                  >
                  
                  </ContentCard>
                </Box>

            

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC22}
                  >
                  
                  </ContentCard>
                </Box>



               
             
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC1}
                  >
                  
                  </ContentCard>
                </Box>

                
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC19}
                  >
                   
                  </ContentCard>
                </Box>


                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC10}
                  >
                   
                  </ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC11}
                  >
                   
                  </ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC12}
                  >
                   
                  </ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC13}
                  >
                   
                  </ContentCard>
                </Box>

               
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC6}
                  >
                   
                  </ContentCard>
                </Box>




                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC15}
                  >
                   
                  </ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC16}
                  >
                   
                  </ContentCard>
                </Box>



           

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC17}
                  >
                   
                  </ContentCard>
                </Box>


           
         
              </SliderStyled>
            </Col>
          </Row>
        </Container>
      </Section>
     
    </>
  );
};

export default RhinogalLiqPAge;
